import * as React from "react";

import "normalize.css";
import * as Yup from "yup";

import {
  AccordionItem as Item,
  ControlledAccordion,
  useAccordionProvider,
  useAccordionState,
} from "@szhsin/react-accordion";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import _ from "lodash";
import { notification } from "antd";
import Header from "../components/Header";
import LayoutRoot from "../components/LayoutRoot";
import LayoutMain from "../components/LayoutMain";
import Footer from "../components/Footer";
import ContactIcon from "../components/ContactIcon";
import Container from "../components/Container";
import { AdminApi } from "../api";
import { defaultAPIConfig } from "../config/defaultApiConfig";
import { useGetCustomerAccount } from "../hooks/useGetCustomerAccount";
import { InputField } from "../components/InputField";
import { StyledButtonSmallBtn } from "../components/CallToAction";
import { convertToAPIErrors } from "../hooks/error/apiErrorConverter";
import { DebugForm } from "../components/DebugForm";
import { AccordionItem } from "../components/BookingProcessModal";
import BookingDetail from "../components/BookingDetail";
import { DropdownField } from "../components/DropdownField";

const dataApi = new AdminApi(defaultAPIConfig());
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Dies ist ein Pflichtfeld."),
  zip: Yup.string().required("Dies ist ein Pflichtfeld."),
  city: Yup.string().required("Dies ist ein Pflichtfeld."),
  street: Yup.string().required("Dies ist ein Pflichtfeld."),
  phone1: Yup.string().required("Dies ist ein Pflichtfeld."),
  email: Yup.string()
    .email("Keine gültige Mailadresse")
    .required("Dies ist ein Pflichtfeld."),
});
const customerAccount: React.FC = () => {
  const { errors, isLoading, user } = useGetCustomerAccount();
  const [api, contextHolder] = notification.useNotification();

  const providerValue = useAccordionProvider({
    allowMultiple: false,
    transition: true,
    transitionTimeout: 200,
    mountOnEnter: true,
    unmountOnExit: true,
  });
  return (
    <LayoutRoot>
      <Header title="Kundenkonto" />
      <LayoutMain>
        <Container>
          {errors.length > 0 && !_.isUndefined(user) ? (
            <>Sie sind nicht eingeloggt. Klicken Sie auf "Kundenlogin".</>
          ) : (
            <>
              <h2>
                Kundenbereich -{" "}
                {user &&
                  user.customergroup &&
                  user.customergroup.data &&
                  user.customergroup.data.attributes.group}
              </h2>
              <p>Willkommen in Ihrem Kundenkonto</p>

              <ControlledAccordion providerValue={providerValue}>
                <AccordionItem
                  header="Buchungen"
                  itemKey="step-1"
                  initialEntered
                >
                  {user &&
                  user.bookings &&
                  user.bookings.data &&
                  user.bookings.data.length > 0 ? (
                    <ul>
                      {user.bookings.data.map((booking: any) => (
                        <BookingDetail booking={booking} />
                      ))}
                    </ul>
                  ) : (
                    <p>Es sind noch keine Buchungen vorhanden.</p>
                  )}
                </AccordionItem>
                <AccordionItem
                  header="Personalien aktualisieren"
                  itemKey="step-2"
                >
                  <Formik
                    validationSchema={validationSchema}
                    initialValues={{
                      salutation: (user && user.salutation) || "",
                      name: (user && user.name) || "",
                      firstName: (user && user.firstName) || "",
                      company: (user && user.company) || "",
                      extension1: (user && user.extension1) || "",
                      extension2: (user && user.extension2) || "",
                      street: (user && user.street) || "",
                      number: (user && user.number) || "",
                      zip: (user && user.zip) || "",
                      city: (user && user.city) || "",
                      country: (user && user.country) || "",
                      phone1: (user && user.phone1) || "",
                      email: (user && user.email) || "",
                    }}
                    onSubmit={(values: any) => {
                      const payload = {
                        updateCustomerAccountRequest: {
                          data: {
                            type: "updatecustomeraccount",
                            account: (user && user.account) || "",
                            attributes: {
                              name: values.name,
                              firstName: values.firstName,
                              company: values.company,
                              extension1: values.extension1,
                              extension2: values.extension2,
                              street: values.street,
                              number: values.number,
                              zip: values.zip,
                              city: values.city,
                              country: values.country,
                              phone1: values.phone1,
                              email: values.email,
                              salutation: values.salutation, // TODO: add salutation field
                            },
                          },
                        },
                      };
                      const updateCustomerAccountRequest = async () => {
                        try {
                          const updateCustomerAccountResponse =
                            await dataApi.customeraccountPut(payload, {
                              headers: {
                                accept: "application/vnd.api+json",
                                "content-type": "application/vnd.api+json",
                              },
                            });

                          if (updateCustomerAccountResponse) {
                            alert("Erfolgreich aktualisiert!");
                          }
                        } catch (error: any) {
                          const apiErrors = await convertToAPIErrors(error);
                          console.log(error);
                          alert("Es ist ein Fehler aufgetreten!");
                        }
                      };

                      updateCustomerAccountRequest();
                    }}
                  >
                    <>
                      <Form style={{ width: "100%" }}>
                        <DropdownField
                          name="salutation"
                          label="Anrede"
                          options={[
                            { value: "none", label: "Nicht ausgewählt" },
                            { value: "mr", label: "Herr" },
                            { value: "mrs", label: "Frau" },
                          ]}
                        />
                        <InputField name="name" label="Name*" />
                        <InputField name="firstName" label="Vorname" />
                        <InputField name="company" label="Firma" />
                        <InputField name="street" label="Strasse*" />
                        <InputField name="number" label="Nr." />
                        <InputField name="extension1" label="Adresszusatz 1" />
                        <InputField
                          name="extension2"
                          label="Kostenstelle oder Referenz"
                        />
                        <InputField name="zip" label="PLZ*" />
                        <InputField name="city" label="Ort*" />
                        <InputField name="country" label="Land" />
                        <InputField name="phone1" label="Telefon*" />
                        <InputField name="email" label="EMail*" />
                        <StyledButtonSmallBtn type="submit">
                          Benutzerdaten aktualisieren
                        </StyledButtonSmallBtn>
                      </Form>
                    </>
                  </Formik>
                </AccordionItem>
              </ControlledAccordion>
            </>
          )}
        </Container>
      </LayoutMain>
      <Footer />
      <ContactIcon />
    </LayoutRoot>
  );
};

export default customerAccount;
