import * as React from "react";
import { useState } from "react";
import dayjs from "dayjs";
import Modal from "./Modal";
import _ from "lodash";

interface BookingDetailProps {
  booking: any;
}

const BookingDetail: React.FC<BookingDetailProps> = ({ booking }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const startLocationName = _.find(
    booking.relationships.rentallocations.data,
    (rentalLocation) => {
      if (rentalLocation.id === booking.attributes.pickupLocationId) {
        return true;
      }
      return false;
    }
  );
  const endLocationName = _.find(
    booking.relationships.rentallocations.data,
    (rentalLocation) => {
      if (rentalLocation.id === booking.attributes.returnLocationId) {
        return true;
      }
      return false;
    }
  );

  return (
    <>
      <li
        style={{ color: "rgb(3, 73, 138)", marginBottom: "10px" }}
        key={booking.id}
      >
        <a
          href="#"
          onClick={(e) => {
            setIsModalOpen(true);
          }}
        >
          Buchungs-Nummer: {booking.id && booking.id} - Datum:{" "}
          {dayjs(booking.attributes.start).format("DD.MM.YYYY HH:mm")} -{" "}
          {dayjs(booking.attributes.end).format("DD.MM.YYYY HH:mm")} - Modell:{" "}
          {booking.attributes.vehicle.model && booking.attributes.vehicle.model}{" "}
        </a>
      </li>
      <Modal
        title="Buchungsinfos"
        modalOpen={isModalOpen}
        setModalOpen={setIsModalOpen}
      >
        Datum: {dayjs(booking.attributes.start).format("DD.MM.YYYY HH:mm")} -{" "}
        {dayjs(booking.attributes.end).format("DD.MM.YYYY HH:mm")} <br />
        Abholstandort: {startLocationName.attributes.name}
        <br />
        Rückgabestandort: {endLocationName.attributes.name}
        <br />
        {booking.attributes.vehicle.model &&
          booking.attributes.vehicle.model}{" "}
        <br />
        Kontrollschild:{" "}
        {booking.attributes.vehicle.licensePlate &&
          booking.attributes.vehicle.licensePlate}{" "}
        <br />
        Total {booking.attributes.totalPrice.total.toFixed(2)} CHF{" "}
        {booking.attributes.totalPrice.vat > 0 && (
          <>inkl. {booking.attributes.totalPrice.vat.toFixed(2)} CHF MwSt.</>
        )}
        <br />
      </Modal>
    </>
  );
};

export default BookingDetail;
